import expandRow from "./expand.vue";
export default {
  name: "render",
  components: {
    expandRow
  },
  data() {
    return {
      maxHeight: 510,
      infoDrawerVisible: false,
      // 信息查看
      editDrawerVisible: false,
      // 编辑
      picVisible: false,
      // 图片预览
      columns: [
      // 表头
      {
        type: "selection",
        width: 60,
        align: "center"
      }, {
        type: "expand",
        width: 50,
        render: (h, params) => {
          return h(expandRow, {
            props: {
              row: params.row
            }
          });
        }
      }, {
        type: "index",
        width: 60,
        align: "center"
      }, {
        title: "用户",
        key: "name",
        minWidth: 190,
        sortable: true,
        render: (h, params) => {
          return h("div", [h("a", {
            on: {
              click: () => {
                this.showDetail(params.row);
              }
            }
          }, params.row.name)]);
        }
      }, {
        title: "图片(点击可预览)",
        key: "createTime",
        align: "center",
        width: 150,
        render: (h, params) => {
          return h("img", {
            attrs: {
              src: params.row.avatar,
              alt: "加载图片失败"
            },
            style: {
              cursor: "pointer",
              width: "40px",
              height: "40px",
              "margin-top": "5px",
              "object-fit": "contain"
            },
            on: {
              click: () => {
                this.showPic(params.row);
              }
            }
          });
        }
      }, {
        title: "状态",
        key: "status",
        align: "center",
        width: 150,
        render: (h, params) => {
          return h("i-switch", {
            props: {
              value: params.row.status,
              size: "large",
              "true-value": 0,
              "false-value": -1
            },
            on: {
              "on-change": v => {
                this.changeStatus(params.row, v);
              }
            }
          }, [h("span", {
            slot: "open"
          }, "启用"), h("span", {
            slot: "close"
          }, "禁用")]);
        }
      }, {
        title: "价格设定",
        key: "price",
        align: "center",
        width: 150,
        render: (h, params) => {
          return h("InputNumber", {
            props: {
              value: params.row.price,
              min: 0,
              precision: 2
            },
            on: {
              "on-change": v => {
                this.changePrice(params.row, v);
              }
            }
          });
        }
      }, {
        title: "审核操作",
        key: "value",
        width: 200,
        render: (h, params) => {
          return h("Select", {
            props: {
              value: params.row.value,
              transfer: true
            },
            on: {
              "on-change": v => {
                this.changeSelect(params.row, v);
              }
            }
          }, [h("Option", {
            props: {
              value: 0
            }
          }, "待审核"), h("Option", {
            props: {
              value: 1
            }
          }, "审核通过"), h("Option", {
            props: {
              value: -1
            }
          }, "审核驳回")]);
        }
      }, {
        title: "操作",
        key: "action",
        align: "center",
        width: 250,
        fixed: "right",
        render: (h, params) => {
          return h("div", [h("Button", {
            props: {
              type: "primary",
              size: "small",
              icon: "ios-create-outline"
            },
            style: {
              marginRight: "5px"
            },
            on: {
              click: () => {
                this.edit(params.row);
              }
            }
          }, "编辑"), h("Dropdown", {
            props: {
              transfer: true
            },
            on: {
              "on-click": v => {
                this.changeDropDown(params.row, v);
              }
            }
          }, [h("Button", {
            props: {
              size: "small"
            },
            style: {
              height: "23.5px"
            }
          }, ["更多操作", h("Icon", {
            props: {
              type: "ios-arrow-down"
            }
          })]), h("DropdownMenu", {
            slot: "list"
          }, [h("DropdownItem", {
            props: {
              name: "reset"
            }
          }, "重置密码"), h("DropdownItem", {
            props: {
              name: "delete"
            }
          }, "删除")])])]);
        }
      }],
      data: [],
      // 表单数据
      infoForm: {},
      options: {
        shortcuts: [{
          text: "今天",
          value() {
            return new Date();
          },
          onClick: picker => {
            this.$Message.info("Click today");
          }
        }, {
          text: "昨天",
          value() {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
          onClick: picker => {
            this.$Message.info("Click yesterday");
          }
        }, {
          text: "一周前",
          value() {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          },
          onClick: picker => {
            this.$Message.info("Click a week ago");
          }
        }]
      }
    };
  },
  methods: {
    init() {
      this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      this.getDataList();
    },
    getDataList() {
      // 以下为模拟数据
      this.data = [{
        id: "1",
        name: "XBoot(点我查看详细信息)",
        email: "1012139570@qq.com",
        birth: "2018-08-08",
        website: "http://xboot.exrick.cn",
        mobile: "18782059033",
        github: "https://github.com/Exrick/XBoot",
        avatar: "https://s1.ax1x.com/2018/05/19/CcdVQP.png",
        value: 0,
        status: 0,
        price: 18.88,
        date: "2018-08-08",
        desc: "XBoot开发平台"
      }, {
        id: "2",
        name: "Exrick(点我查看详细信息)",
        email: "1012139570@qq.com",
        birth: "2018-08-08",
        website: "http://exrick.cn",
        mobile: "18782059032",
        github: "https://github.com/Exrick",
        avatar: "https://ooo.0o0.ooo/2021/01/16/VuODA1yUSCeXzFM.png",
        value: 1,
        status: -1,
        price: 66.66,
        date: "2018-08-08",
        desc: "XBoot作者"
      }];
    },
    showDetail(v) {
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.infoForm = data;
      this.infoDrawerVisible = true;
    },
    showPic(v) {
      this.infoForm.avatar = v.avatar;
      this.picVisible = true;
    },
    changeStatus(row, v) {
      this.$Message.success("修改用户 " + row.name + " 状态为 " + v + " 成功");
    },
    changePrice(row, v) {
      this.$Message.success("修改 " + row.name + " 价格为 " + v + " 成功");
    },
    changeSelect(row, v) {
      this.$Message.success("修改 " + row.name + " 审核状态为 " + v + " 成功");
    },
    changeDropDown(row, v) {
      this.$Message.info("点击了 " + row.name + " 的 " + v);
    },
    edit(v) {
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.infoForm = data;
      this.editDrawerVisible = true;
    },
    submitEdit() {
      this.$Message.success("编辑成功");
      this.editDrawerVisible = false;
    }
  },
  mounted() {
    this.init();
  }
};